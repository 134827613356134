'use client';

import { ErrorPlaceholder } from '@/components/placeholders';
import { logger } from '@/logger';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  logger.error('Error', error);
  return <ErrorPlaceholder onClick={() => reset()} />;
}
